import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import LayoutHeader from "../../../components/LayoutHeader";
import LayoutPageContent from "../../../components/LayoutPageContent";
import LayoutSidebar from "../../../components/LayoutSidebar";
import PageTitle from "../../../components/PageTitle";
import TermsEdit from "../../../components/TermsEdit";
import usePageTitle from "../../usePageTitle";
import { ReactComponent as Icon } from "../ic_32_privacy_privacy_bk.svg";

const Terms: FC = () => {
  usePageTitle("Terms of Use Update Edit");
  const { t } = useTranslation();
  const { termsId } = useParams();

  return (
    <LayoutHeader>
      <LayoutSidebar>
        <PageTitle icon={<Icon />} title={t("Terms of Use Update Edit")} />
        <LayoutPageContent>
          <TermsEdit termsId={Number(termsId)} />
        </LayoutPageContent>
      </LayoutSidebar>
    </LayoutHeader>
  );
};
export default Terms;
