import { TFunction } from "i18next";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getAuthedWebApi } from "../../webapi";
import type { Information } from "../../webapi/generated";
import DateJST from "../DateJST";
import EllipsisByLineCount from "../EllipsisByLineCount";
import { usePage } from "../hooks";
import Pager from "../Pager";
import Status from "../Status";
import TableTheme from "../TableTheme";
import { ReactComponent as PlusIcon } from "./ic_32_add.svg";
import styles from "./InformationList.module.css";

type Props = {};

const InformationList: FC<Props> = () => {
  const { t } = useTranslation();

  const page = usePage();
  const result = useSearchResult(page);

  return (
    <div className={styles.host}>
      <div className={styles.tableAhead}>
        <Link to="/informations/new" className={styles.newButton}>
          <PlusIcon />
          <span>{t("Information Registration")}</span>
        </Link>
      </div>
      {result && (
        <TableTheme>
          <table>
            <thead>
              <tr>
                <th className={styles.titleColumn}>{t("Title")}</th>
                <th className={styles.detailColumn}>{t("Detail")}</th>
                <th className={styles.publicationPeriodColumn}>
                  {t("Publication Period")}
                </th>
                <th className={styles.statusColumn}>{t("Status")}</th>
                <th className={styles.displayColumn}>{t("Display")}</th>
              </tr>
            </thead>
            <tbody>
              {result.informations.map((information) => (
                <tr
                  key={information.id}
                  className={
                    information.isEnabled ? undefined : styles.disabled
                  }
                >
                  <td className={styles.titleColumn}>
                    <EllipsisByLineCount max={3}>
                      <Link to={`/informations/${information.id}`}>
                        {information.title}
                      </Link>
                    </EllipsisByLineCount>
                  </td>
                  <td className={styles.detailColumn}>
                    <EllipsisByLineCount max={3}>
                      {information.detail}
                    </EllipsisByLineCount>
                  </td>
                  <td className={styles.publicationPeriodColumn}>
                    <DateJST value={information.publishStart} /> ~{" "}
                    <DateJST value={information.publishEnd} />
                  </td>
                  <td className={styles.statusColumn}>
                    <Status enabled={information.isEnabled} />
                  </td>
                  <td className={styles.displayColumn}>
                    {displayPages(t, information)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableTheme>
      )}
      {result?.count === 0 && (
        <div className={styles.noSearchResult}>{t("No results found")}</div>
      )}
      <div className={styles.pager}>
        <Pager
          max={Math.ceil((result?.count ?? 0) / 20)}
          current={page}
          navigateTo={(i) => `/informations?page=${i}`}
        />
      </div>
    </div>
  );
};
export default InformationList;

function useSearchResult(page: number) {
  const [informations, setInformations] = useState<Information[]>([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    (async () => {
      const webapi = await getAuthedWebApi();
      const result = await webapi.getInformations({ page, limit: 20 });
      setInformations(result.informations);
      setCount(result.count || 0);
    })();
  }, [page]);

  return { informations, count };
}

function displayPages(t: TFunction, information: Information) {
  const { displayOn } = information;
  const pages = displayOn
    .filter((x) => x.isDisplaying)
    .map((x) => x.displayPage);
  const pageNames = pages
    .map((x) => x.name)
    .filter((x): x is string => Boolean(x));
  return pageNames.map((x) => t(x)).join("/");
}
