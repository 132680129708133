/**
 * 未ログイン状態を表すエラー
 */
export class AppErrorNotLogin extends Error {}

/**
 * 管理者認証失敗を表すエラー
 */
export class AppErrorAdminAuthorization extends Error {}

export interface ErrorValue {
  title: string;
  detail?: string;
}

export function isAppErrorNotLoginError(x: unknown): boolean {
  if (x instanceof AppErrorNotLogin) return true;
  return false;
}

export async function isInvalidLoginSessionError(x: unknown): Promise<boolean> {
  try {
    if (!(x instanceof Response)) return false;
    if (x.status !== 403) return false;
    const data = await x.json();
    if (
      !(
        typeof data === "object" &&
        data !== null &&
        Array.isArray(data.errors) &&
        data.errors.length > 0
      )
    )
      return false;
    return ["E403-2", "E403-3"].includes(data.errors[0].errorCode);
  } catch {}
  return false;
}

export async function convertToError(x: unknown): Promise<ErrorValue> {
  if (x instanceof Response) {
    let body: string;
    try {
      body = await x.text();
    } catch (e) {
      body = `Failed to read response body: ${await convertToString(e)}`;
    }
    return {
      title: `HTTP Response ${x.status}`,
      detail: `${x.url}\n${body}`,
    };
  }

  return {
    title: "Unknown Error",
    detail: await convertToString(x),
  };
}

export async function convertToString(x: unknown): Promise<string> {
  if (typeof x === "object" && x !== null) {
    const obj: any = x;
    if (obj.message) return String(obj.message);
    if (obj.toString instanceof Function) return String(obj.toString());
  }
  return String(x);
}
