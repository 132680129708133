import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LayoutHeader from "../../components/LayoutHeader";
import LayoutPageContent from "../../components/LayoutPageContent";
import LayoutSidebar from "../../components/LayoutSidebar";
import PageTitle from "../../components/PageTitle";
import UserEdit from "../../components/UserEdit";
import usePageTitle from "../usePageTitle";
import { ReactComponent as Icon } from "./ic_32_user_user_bk.svg";

const Users: FC = () => {
  usePageTitle("Edit User Profile");
  const { t } = useTranslation();
  const { userId } = useParams();

  return (
    <LayoutHeader>
      <LayoutSidebar>
        <PageTitle icon={<Icon />} title={t("Edit User Profile")} />
        <LayoutPageContent>
          <UserEdit userId={Number(userId)} />
        </LayoutPageContent>
      </LayoutSidebar>
    </LayoutHeader>
  );
};
export default Users;
