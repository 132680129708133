import { useEffect, useState } from "react";
import { Country } from "../../webapi/generated";
import { fetchCountries } from "../../usecase/country";

export function useCountries() {
  const [organizations, setOrganization] = useState<Country[]>([]);

  useEffect(() => {
    fetchCountries().then((result) => {
      setOrganization(result.countries);
    });
  }, []);

  return organizations;
}
