import type { FC } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate } from "react-router-dom";
import type { InvalidParameterErrorErrors } from "../../webapi/generated";
import Date from "../Date";
import styles from "./OrganizationEdit.module.css";
import OrganizationForm from "../OrganizationForm";
import { useOrganization } from "./hooks";
import {
  deleteOrganization,
  putOrganization,
} from "../../usecase/organization";
import { useFlashMessageDispatch } from "../FlashMessage/context";

type Props = {
  organizationId: number;
};

const OrganizationEdit: FC<Props> = ({ organizationId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const submitted = useRef(false);
  useBlocker((tx) => {
    if (
      submitted.current ||
      window.confirm(
        t(
          "Input contents are discarded. You cannot start over. Are you sure you want to discard it?"
        )
      )
    )
      tx.retry();
  });

  const [errors, setErrors] = useState<InvalidParameterErrorErrors[]>();

  const organization = useOrganization(organizationId);
  const canDelete = !!organization && organization.canDelete;
  const flash = useFlashMessageDispatch();

  return (
    <div className={styles.host}>
      <div className={styles.ahead}>
        <div className={styles.timestamps}>
          <div>
            {t("Registration Date")} : <Date value={organization?.createdAt} />
          </div>
          <div>
            {t("Last Update")} : <Date value={organization?.updatedAt} />
          </div>
        </div>
      </div>
      <OrganizationForm
        defaultValue={organization}
        deletable={canDelete}
        serverErrors={errors}
        onSubmit={(data) => {
          putOrganization(organizationId, data)
            .then(() => {
              flash(t("The organization profile was edited successfully."));
              submitted.current = true;
              navigate("/organizations");
            })
            .catch(async (exception: unknown) => {
              if (exception instanceof Response && exception.status === 400) {
                const responseBody = await exception.json();
                setErrors(responseBody.errors);
                return;
              }
              throw exception;
            });
        }}
        onDelete={() => {
          if (window.confirm(t("Do you want to permanently delete it?")))
            deleteOrganization(organizationId).then(() => {
              flash(t("The organization profile has been deleted."));
              submitted.current = true;
              navigate("/organizations");
            });
        }}
        onCancel={() => navigate("/organizations")}
      />
    </div>
  );
};
export default OrganizationEdit;
