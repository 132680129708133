import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EndUserLicenseVersionAgreementInfo } from "../../../webapi/generated";
import DateJST from "../../DateJST";
import EllipsisByLineCount from "../../EllipsisByLineCount";
import TableTheme from "../../TableTheme";
import styles from "./SearchResultTable.module.css";

type Props = {
  result?: {
    endUserLicenseVersions: EndUserLicenseVersionAgreementInfo[],
    count: number
  };
};

const SearchResultTable: FC<Props> = ({ result }) => {
  const { t } = useTranslation();

  if (!result) return null;
  return (
    <TableTheme>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.idColumn}>{t("ID")}</th>
            <th className={styles.titleColumn}>{t("Update Title")}</th>
            <th className={styles.dateColumn}>{t("Application Start Date")}</th>
            <th className={styles.consentRateColumn}>
              {t("Number of consent / Number of users")}
            </th>
          </tr>
        </thead>
        <tbody>
          {result.endUserLicenseVersions?.map((version) => (
            <tr key={version.id}>
              <td className={styles.idColumn}>{version.id}</td>
              <td className={styles.titleColumn}>
                <Link to={`/terms/${version.id}`}>
                  <EllipsisByLineCount max={1}>
                    {version.title}
                  </EllipsisByLineCount>
                </Link>
              </td>
              <td className={styles.dateColumn}>
                <DateJST value={version.startAt} />
              </td>
              <td className={styles.consentRateColumn}>
                <Link to={`/terms/${version.id}/users`}>
                  {version.agreedUserCount} / {version.totalUserCount}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableTheme>
  );
};
export default SearchResultTable;
