import { getAuthedWebApi } from "../webapi";

/**
 * 国一覧の取得
 *
 * @returns レスポンスボディ
 */
export async function fetchCountries() {
  const webapi = await getAuthedWebApi();
  return webapi.getCountries();
}
