import type { FC } from "react";
import AccountMenu from "./AccountMenu";
import styles from "./Header.module.css";
import { ReactComponent as TitleIcon } from "./img_logo_title.svg";

type Props = {};

const Header: FC<Props> = () => {
  const env = getEnvironment();

  return (
    <header className={styles.host}>
      <TitleIcon />
      {env ? <div className={styles.environment}>- {env} -</div> : null}
      <AccountMenu />
    </header>
  );
};
export default Header;

function getEnvironment(): string {
  switch (process.env.REACT_APP_ENV) {
    case "stg":
      return "Staging";
    case "dev":
      return "Development";
    case "qa":
      return "QA";
    case "local":
      return "Local";
    default:
      return "";
  }
}
