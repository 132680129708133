import { useEffect, useMemo, useState } from "react";
import { fetchOrganizations } from "../../usecase/organization";
import { Organization } from "../../webapi/generated";

const organizationsOffset = 20;

export function useOrganizations(page: number) {
  const [organizations, setOrganization] = useState<Organization[]>([]);
  const [count, setCount] = useState(0);

  const requestParams = useMemo(
    () => ({
      page,
      limit: organizationsOffset,
    }),
    [page]
  );

  useEffect(() => {
    fetchOrganizations(requestParams).then((result) => {
      setOrganization(result.organizations);
      setCount(result.count);
    });
  }, [requestParams]);

  const pager = {
    current: page,
    max: Math.ceil(count / organizationsOffset),
  };

  return { organizations, count, pager };
}
