import type { FC } from "react";
import Header from "./Header";
import styles from "./LayoutHeader.module.css";

type Props = {};

const LayoutHeader: FC<Props> = ({ children }) => {
  return (
    <>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.main}>{children}</div>
    </>
  );
};
export default LayoutHeader;
