import type { FC } from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate } from "react-router-dom";
import { getAuthedWebApi } from "../../webapi";
import { useFlashMessageDispatch } from "../FlashMessage/context";
import type { FormData } from "../UserForm";
import UserForm from "../UserForm";
import { InvalidParameterErrorErrors } from "../../webapi/generated";

type Props = {};

const UserNew: FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const submitted = useRef(false);
  useBlocker((tx) => {
    if (
      submitted.current ||
      window.confirm(
        t(
          "Input contents are discarded. You cannot start over. Are you sure you want to discard it?"
        )
      )
    )
      tx.retry();
  });

  const [errors, setErrors] = useState<InvalidParameterErrorErrors[]>();

  const flash = useFlashMessageDispatch();

  return (
    <UserForm
      serverErrors={errors}
      onSubmit={(data) => {
        createNewUser(data)
          .then(() => {
            flash(t("User registration was successful."));
            submitted.current = true;
            navigate("/users");
          })
          .catch(async (exception: unknown) => {
            if (exception instanceof Response && exception.status === 400) {
              const responseBody = await exception.json();
              setErrors(responseBody.errors);
              return;
            }
            throw exception;
          });
      }}
      onCancel={() => navigate("/users")}
    />
  );
};
export default UserNew;

async function createNewUser(data: FormData) {
  const webapi = await getAuthedWebApi();

  await webapi.postUsers({
    userCreate: {
      email: data.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      organizationId: data.organizationId,
      isEnabled: data.status === "enabled",
      role: data.userType,
      remarks: data.remarks,
    },
  });
}
