import type { FC } from "react";
import LayoutSingleBox from "../components/LayoutSingleBox";
import LoginComponent from "../components/Login";
import usePageTitle from "./usePageTitle";

const Login: FC = () => {
  usePageTitle("Login");
  return (
    <LayoutSingleBox>
      <LoginComponent />
    </LayoutSingleBox>
  );
};
export default Login;
