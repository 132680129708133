/**
 * メンテナンス情報
 */
export interface MaintenanceInfo {
  /** 現在のメンテナンス状態 */
  state: "maintenance";
  /** 画面に表示するエンドユーザー向けメッセージ */
  message: string;
}

export type MaintenanceJSON = Omit<MaintenanceInfo, "state"> & {
  state?: string;
};

export function isMaintenanceJSON(x: unknown): x is MaintenanceJSON {
  if (typeof x !== "object" || x === null) return false;
  if (
    typeof (x as any).state !== "undefined" &&
    typeof (x as any).state !== "string"
  )
    return false;
  if (typeof (x as any).message !== "string") return false;

  return true;
}

export function maintenanceInfo(json: MaintenanceJSON): MaintenanceInfo {
  const state = json.state ?? "maintenance";
  if (state !== "maintenance")
    throw new Error("unknown maintenance state: " + state);
  return { ...json, state };
}

export async function getMaintenanceInfo() {
  let info: MaintenanceInfo;
  try {
    const response = await fetch("/maintenance.json");
    if (!response.ok) throw new Error("HTTP Error");
    const data: unknown = await response.json();

    if (!isMaintenanceJSON(data))
      throw new Error("Unexpected maintenance.json format");
    info = maintenanceInfo(data);
  } catch {
    // メンテナンス情報取得エラーは 404 含め、メンテナンス状態でないとみなす
    return null;
  }

  return info;
}
