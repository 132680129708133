import type { FC } from "react";
import { useTranslation } from "react-i18next";
import type { ErrorValue } from "../../../webapi/error";
import Button from "../../Button";
import LayoutEmptyHeader from "../../LayoutEmptyHeader";
import styles from "./SystemError.module.css";
type Props = {
  errors?: ErrorValue[];
};

/**
 * このコンポーネントの責務はシステムエラー画面を提供する
 */
const SystemError: FC<Props> = ({ errors = [] }) => {
  const { t } = useTranslation();

  return (
    <LayoutEmptyHeader>
      <div className={styles.host}>
        <div className={styles.title}>{t("System Error")}</div>
        <div className={styles.message}>
          {errors.map((error, index) =>
            error.detail ? (
              <details key={JSON.stringify({ error, index })}>
                <summary>{error.title}</summary>
                <pre>
                  <code>{error.detail}</code>
                </pre>
              </details>
            ) : (
              <div key={JSON.stringify({ error, index })}>{error.title}</div>
            )
          )}
        </div>
        <div>
          <Button
            type="button"
            className={styles.button}
            onClick={() => {
              window.location.href = "/users";
            }}
          >
            {t("To Home Screen")}
          </Button>
        </div>
      </div>
    </LayoutEmptyHeader>
  );
};
export default SystemError;
