import type { FC } from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EndUserLicenseVersionInfo } from "../../webapi/generated";
import Button from "../Button";
import { toJSTFormat } from "../datetime";
import InputText from "../InputText";
import Textarea from "../Textarea";
import styles from "./TermsForm.module.css";

export type FormData = {
  title: string;
  startAt: Date;
  urlEn: string;
  urlJa: string;
  remarks: string;
};

type Props = {
  defaultValue?: EndUserLicenseVersionInfo;
  validateStartBeforeToday?: boolean;
  onSubmit?: (data: FormData) => void;
  onCancel?: () => void;
};

const TermsForm: FC<Props> = ({
  defaultValue,
  validateStartBeforeToday,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, register, formState, trigger } = useForm({
    mode: "onChange",
  });

  const [error, setError] = useState<"start-before-today">();

  // バリデーションは defaultValue に依存していて、
  // 変化するとバリデーション結果も変わるため強制的に再バリデーションを実行する
  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        setError(undefined);

        const startAt = new Date(`${data.startAt}T00:00:00+09:00`);
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        if (validateStartBeforeToday && startAt.getTime() < today.getTime()) {
          setError("start-before-today");
          return;
        }

        onSubmit?.({
          title: data.title,
          startAt,
          urlEn: data.urlEn,
          urlJa: data.urlJa,
          remarks: data.remarks,
        });
      })}
    >
      <div className={styles.writable}>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("Update Title")}</span>
          <InputText
            name="title"
            defaultValue={defaultValue?.title}
            required
            maxLength={100}
            ref={register({ required: true })}
          />
        </label>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>
            {t("Application Start Date")}
          </span>
          <div>
            <InputText
              type="date"
              name="startAt"
              defaultValue={
                defaultValue ? toJSTFormat(defaultValue.startAt) : undefined
              }
              required
              invalid={error === "start-before-today"}
              className={styles.date}
              ref={register({ required: true })}
            />
            {error === "start-before-today" && (
              <div className={styles.invalid}>
                {t("Please set a future date including today.")}
              </div>
            )}
          </div>
        </label>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("URL / Japanese")}</span>
          <InputText
            name="urlJa"
            defaultValue={defaultValue?.urlJa}
            required
            maxLength={255}
            ref={register({ required: true })}
          />
        </label>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("URL / English")}</span>
          <InputText
            name="urlEn"
            defaultValue={defaultValue?.urlEn}
            required
            maxLength={255}
            ref={register({ required: true })}
          />
        </label>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("Remarks（Optional）")}</span>
          <Textarea
            name="remarks"
            defaultValue={defaultValue?.remarks}
            counter
            maxLength={200}
            ref={register}
          />
        </label>
      </div>
      <div className={styles.footer}>
        <Button className={styles.cancelButton} onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button
          type="submit"
          className={styles.submitButton}
          disabled={!formState.isValid}
        >
          {t("Register")}
        </Button>
      </div>
    </form>
  );
};
export default TermsForm;
