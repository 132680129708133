import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate } from "react-router-dom";
import { getAuthedWebApi } from "../../webapi";
import type { Information } from "../../webapi/generated";
import Date from "../Date";
import { useFlashMessageDispatch } from "../FlashMessage/context";
import type { FormData } from "../InformationForm";
import InformationForm from "../InformationForm";
import styles from "./InformationEdit.module.css";

type Props = {
  informationId: number;
};

const InformationEdit: FC<Props> = ({ informationId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const submitted = useRef(false);
  useBlocker((tx) => {
    if (
      submitted.current ||
      window.confirm(
        t(
          "Input contents are discarded. You cannot start over. Are you sure you want to discard it?"
        )
      )
    )
      tx.retry();
  });

  const flash = useFlashMessageDispatch();

  const information = useInformation(informationId);

  return (
    <div>
      <div className={styles.timestamps}>
        <div>
          {t("Registration Date")} : <Date value={information?.createdAt} />
        </div>
        <div>
          {t("Last Update")} : <Date value={information?.updatedAt} />
        </div>
      </div>
      <InformationForm
        defaultValue={information}
        deletable
        onSubmit={(data) => {
          if (window.confirm(t("Are you sure you want to register?")))
            updateInformation(informationId, data).then(() => {
              flash(t("You have successfully edited the information."));
              submitted.current = true;
              navigate("/informations");
            });
        }}
        onDelete={() => {
          if (
            window.confirm(
              t(
                "Delete this information. You cannot start over. Are you sure you want to delete it?"
              )
            )
          )
            deleteInformation(informationId).then(() => {
              flash(t("The information was deleted successfully."), "orange");
              submitted.current = true;
              navigate("/informations");
            });
        }}
        onCancel={() => navigate("/informations")}
      />
    </div>
  );
};
export default InformationEdit;

function useInformation(informationId: Props["informationId"]) {
  const [information, setInformation] = useState<Information>();

  useEffect(() => {
    (async () => {
      const webapi = await getAuthedWebApi();
      const result = await webapi.getInformationsInformationId({
        informationId,
      });
      setInformation(result);
    })();
  }, [informationId]);

  return information;
}

async function deleteInformation(informationId: Props["informationId"]) {
  const webapi = await getAuthedWebApi();
  await webapi.deleteInformationsInformationId({ informationId });
}

async function updateInformation(
  informationId: Props["informationId"],
  data: FormData
) {
  const webapi = await getAuthedWebApi();
  await webapi.putInformationsInformationId({
    informationId,
    informationUpdate: data,
  });
}
