import type { FC } from "react";
import { toJSTFormat } from "../datetime";

type Props = {
  value?: Date;
};

/**
 * このコンポーネントの責務は Date インスタンスをこのプロジェクト特有の日付表示方法で
 * フォーマットした文字列に変換すること (タイムゾーンは日本標準時に固定)
 */
const Date: FC<Props> = ({ value }) => {
  return value ? <>{toJSTFormat(value).split("-").join("/")}</> : null;
};
export default Date;
