import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { User } from "../../../webapi/generated";
import Date from "../../Date";
import EllipsisByLineCount from "../../EllipsisByLineCount";
import Status from "../../Status";
import TableTheme from "../../TableTheme";
import Time from "../../Time";
import styles from "./SearchResultTable.module.css";

type Props = {
  result?: {
    users: User[];
    count: number;
  };
};

const SearchResultTable: FC<Props> = ({ result }) => {
  const { t } = useTranslation();

  if (!result) return null;
  return (
    <TableTheme>
      <table className={styles.fixedTable}>
        <caption>
          {t("Total Search Results")} : {result.count}
        </caption>
        <thead>
          <tr>
            <th className={styles.userIdColumn}>{t("User ID / User Name")}</th>
            <th className={styles.organizationColumn}>{t("Belong to")}</th>
            <th className={styles.statusColumn}>{t("Status")}</th>
            <th className={styles.roleColumn}>{t("User's Role")}</th>
            <th className={styles.registrationDateColumn}>
              {t("Registration Date")}
            </th>
            <th className={styles.lastAccessColumn}>{t("Last Access")}</th>
          </tr>
        </thead>
        <tbody>
          {result.users.map((user) => (
            <tr
              key={user.email}
              className={user.isEnabled ? undefined : styles.disabled}
            >
              <td className={styles.userIdColumn}>
                <EllipsisByLineCount max={1}>
                  <Link to={`/users/${user.id}`}>{user.email}</Link>
                </EllipsisByLineCount>
                <EllipsisByLineCount max={1}>{user.name}</EllipsisByLineCount>
              </td>
              <td className={styles.organizationColumn}>
                <EllipsisByLineCount max={2}>
                  {user.organizationName}
                </EllipsisByLineCount>
              </td>
              <td className={styles.statusColumn}>
                <Status enabled={user.isEnabled} />
              </td>
              <td className={styles.roleColumn}>
                <EllipsisByLineCount max={1}>
                  {t(String(user.role))}
                </EllipsisByLineCount>
              </td>
              <td className={styles.registrationDateColumn}>
                <Date value={user.createdAt} />
              </td>
              <td className={styles.lastAccessColumn}>
                {user.lastLoginAt ? (
                  <>
                    <Date value={user.lastLoginAt} />{" "}
                    <Time value={user.lastLoginAt} />
                  </>
                ) : (
                  <span>-</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableTheme>
  );
};
export default SearchResultTable;
