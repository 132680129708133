import { getAuthedWebApi } from "../webapi";
import {
  GetOrganizationsRequest,
  OrganizationCreate,
  OrganizationUpdate,
} from "../webapi/generated";

/**
 * 所属一覧の取得
 *
 * @param params リクエストパラメータ
 * @returns レスポンスボディ
 */
export async function fetchOrganizations(params: GetOrganizationsRequest) {
  const webapi = await getAuthedWebApi();
  return webapi.getOrganizations(params);
}

/**
 * 所属詳細の取得
 *
 * @param id 所属ID
 * @returns レスポンスボディ
 */
export async function fetchOrganizationDetail(id: number) {
  const webapi = await getAuthedWebApi();
  return webapi.getOrganizationsOrganizationId({ organizationId: id });
}

/**
 * 所属登録処理
 *
 * @params data OrganizationCreate 所属登録情報
 */
export async function postOrganizations(data: OrganizationCreate) {
  const webapi = await getAuthedWebApi();
  await webapi.postOrganizations({ organizationCreate: data });
}

/**
 * 所属更新処理
 *
 * @param id 所属id
 * @param data OrganizationUpdate 所属更新情報
 */
export async function putOrganization(id: number, data: OrganizationUpdate) {
  const webapi = await getAuthedWebApi();
  await webapi.putOrganizationsOrganizationId({
    organizationId: id,
    organizationUpdate: data,
  });
}

/**
 * 所属削除処理
 *
 * @param organizationId 所属id
 */
export async function deleteOrganization(organizationId: number) {
  const webapi = await getAuthedWebApi();
  await webapi.deleteOrganizationsOrganizationId({ organizationId });
}
