import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate } from "react-router-dom";
import { getAuthedWebApi } from "../../webapi";
import type {
  UserRole,
  User,
  InvalidParameterErrorErrors,
} from "../../webapi/generated";
import Date from "../Date";
import { useFlashMessageDispatch } from "../FlashMessage/context";
import Time from "../Time";
import type { FormData } from "../UserForm";
import UserForm from "../UserForm";
import styles from "./UserEdit.module.css";

type Props = {
  userId: number;
};

const UserEdit: FC<Props> = ({ userId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const submitted = useRef(false);
  useBlocker((tx) => {
    if (
      submitted.current ||
      window.confirm(
        t(
          "Input contents are discarded. You cannot start over. Are you sure you want to discard it?"
        )
      )
    )
      tx.retry();
  });

  const [errors, setErrors] = useState<InvalidParameterErrorErrors[]>();

  const flash = useFlashMessageDispatch();

  const user = useUser(userId);
  const isMe = useIsMe(userId);

  return (
    <div className={styles.host}>
      <div className={styles.ahead}>
        <div className={styles.timestamps}>
          <div>
            {t("Registration Date")} : <Date value={user?.createdAt} />
          </div>
          <div>
            {t("Last Access")} : <Date value={user?.lastLoginAt} />{" "}
            <Time value={user?.lastLoginAt} />
          </div>
          <div>
            {t("Last Update")} : <Date value={user?.updatedAt} />
          </div>
          <div>
            {t("Last Agreement date")} :{" "}
            <Date value={user?.lastEndUserLicenseAgreedAt} />
          </div>
        </div>
      </div>
      <UserForm
        defaultValue={user}
        deletable={!isMe}
        hiddenStatus={isMe}
        serverErrors={errors}
        onSubmit={(data) => {
          updateUser(userId, data)
            .then(() => {
              flash(t("The user profile was edited successfully."));
              submitted.current = true;
              navigate("/users");
            })
            .catch(async (exception: unknown) => {
              if (exception instanceof Response && exception.status === 400) {
                const responseBody = await exception.json();
                setErrors(responseBody.errors);
                return;
              }
              throw exception;
            });
        }}
        onDelete={() => {
          if (window.confirm(t("Do you want to permanently delete it?")))
            deleteUser(userId).then(() => {
              flash(t("The user profile has been deleted."), "orange");
              submitted.current = true;
              navigate("/users");
            });
        }}
        onCancel={() => navigate("/users")}
      />
    </div>
  );
};
export default UserEdit;

function useUser(userId: Props["userId"]) {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    (async () => {
      const webapi = await getAuthedWebApi();
      const result = await webapi.getUsersUserId({ userId });
      setUser(result);
    })();
  }, [userId]);

  return user;
}

async function deleteUser(userId: Props["userId"]) {
  const webapi = await getAuthedWebApi();
  await webapi.deleteUsersUserId({ userId });
}

async function updateUser(userId: Props["userId"], data: FormData) {
  const webapi = await getAuthedWebApi();
  await webapi.putUsersUserId({
    userId,
    userUpdate: {
      email: data.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      organizationId: data.organizationId,
      isEnabled: data.status === "enabled",
      role: data.userType as UserRole,
      remarks: data.remarks,
    },
  });
}

function useIsMe(userId: Props["userId"]) {
  const [isMe, setIsMe] = useState(false);

  useEffect(() => {
    (async () => {
      const webapi = await getAuthedWebApi();
      const user = await webapi.getUser();
      setIsMe(user.id === userId);
    })();
  }, [userId]);

  return isMe;
}
