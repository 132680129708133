import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { getAuthedWebApi } from "../../webapi";
import { UserRole, User } from "../../webapi/generated";
import { usePage } from "../hooks";
import Pager from "../Pager";
import { ReactComponent as PlusIcon } from "./ic_32_add.svg";
import type { FormData } from "./SearchForm";
import SearchForm, { SearchUserRole } from "./SearchForm";
import SearchResultTable from "./SearchResultTable";
import styles from "./UserList.module.css";

type Props = {};

const UserList: FC<Props> = () => {
  const { t } = useTranslation();

  const [query, setQuery] = useQuery();
  const page = usePage();
  const result = useSearchResult(query, page);

  return (
    <div>
      <SearchForm defaultValues={query} onSearch={setQuery} />
      <div className={styles.tableView}>
        <Link to="/users/new" className={styles.newUserButton}>
          <PlusIcon />
          <span>{t("Add New User")}</span>
        </Link>
        <SearchResultTable result={result} />
        {result?.count === 0 && (
          <div className={styles.noSearchResult}>{t("No results found")}</div>
        )}
      </div>
      <div className={styles.pager}>
        <Pager
          max={Math.ceil((result?.count ?? 0) / 20)}
          current={page}
          navigateTo={(i) => `/users${queryToString(query, i)}`}
        />
      </div>
    </div>
  );
};
export default UserList;

function useQuery() {
  const [searchParams, setSearchParams] = useSearchParams();

  const userRoles: UserRole[] = [];
  searchParams.getAll("roles").forEach((x) => {
    Object.values(UserRole).forEach((role) => {
      if (x === role) {
        userRoles.push(role);
      }
    });
    // TODO: Organization Administrator(会社管理者が追加された時こちらに追加する)
    if (x === SearchUserRole.Administrator) {
      userRoles.push(UserRole.SystemAdministrator);
    }
  });

  const query: Partial<FormData> = {
    userId: searchParams.get("userId") ?? undefined,
    userName: searchParams.get("userName") ?? undefined,
    organizationName: searchParams.get("organizationName") ?? undefined,
    status: searchParams
      .getAll("status")
      .filter((x): x is "enabled" | "disabled" =>
        ["enabled", "disabled"].includes(x)
      ),
    roles: userRoles,
  };
  const setQuery = (data: Partial<FormData>) => {
    setSearchParams(queryToString(data));
  };

  return [query, setQuery] as const;
}

function queryToString(query: Partial<FormData>, page?: number) {
  const e = encodeURIComponent;
  let pairs: string[] = [];

  for (const [k, v] of Object.entries(query)) {
    if (!v) continue;
    if (Array.isArray(v)) {
      v.forEach((x: string) => pairs.push(`${e(k)}=${e(x)}`));
    } else {
      pairs.push(`${e(k)}=${e(v)}`);
    }
  }

  if (typeof page === "number") pairs.push(`page=${page}`);

  if (pairs.length === 0) return "";
  return "?" + pairs.join("&");
}

function useSearchResult(query: Partial<FormData>, page: number) {
  const [users, setUsers] = useState<User[]>([]);
  const [count, setCount] = useState(0);

  /* eslint-disable react-hooks/exhaustive-deps */
  // query は中身にかかわらず常に新しいオブジェクトとなるので、
  // useEffect が無限ループしないように、中身によってメモ化した requestParams を作る

  const requestParams = useMemo(
    () => ({
      email: query.userId,
      name: query.userName,
      organizationName: query.organizationName,
      isEnabled:
        query.status?.includes("enabled") && !query.status?.includes("disabled")
          ? true
          : !query.status?.includes("enabled") &&
            query.status?.includes("disabled")
          ? false
          : undefined,
      roles: query.roles?.length !== 0 ? query.roles : undefined,
      page,
      limit: 20,
    }),
    [
      query.userId,
      query.userName,
      query.organizationName,
      query.status?.[0],
      query.status?.[1],
      query.roles?.[0],
      query.roles?.[1],
      query.roles?.[2],
      page,
    ]
  );

  useEffect(() => {
    if (!query) {
      setUsers([]);
      setCount(0);
      return;
    }

    (async () => {
      const webapi = await getAuthedWebApi();
      const result = await webapi.getUsers(requestParams);
      setUsers(result.users);
      setCount(result.count);
    })();
  }, [requestParams]);
  /* eslint-enable */

  return { users, count };
}
