import type { FC } from "react";
import styles from "./EllipsisByLineCount.module.css";

type Props = {
  max: number;
};

/**
 * このコンポーネントの責務は与えた子要素のテキストが指定行数以上になる場合は
 * 省略して (末尾を省略記号で置き換えて) 表示すること
 */
const EllipsisByLineCount: FC<Props> = ({ max, children }) => {
  return (
    <div className={styles.host} style={{ WebkitLineClamp: max }}>
      {children}
    </div>
  );
};
export default EllipsisByLineCount;
