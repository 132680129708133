import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { EndUserLicenseVersionAgreementInfo } from "../../../webapi/generated";
import Date from "../../Date";
import EllipsisByLineCount from "../../EllipsisByLineCount";
import TableTheme from "../../TableTheme";
import styles from "./SearchResultTable.module.css";

type Props = {
  result?: EndUserLicenseVersionAgreementInfo;
};

const SearchResultTable: FC<Props> = ({ result }) => {
  const { t } = useTranslation();

  if (!result) return null;
  return (
    <TableTheme>
      <table className={styles.table}>
        <caption>
          <span>
            {t("Application Start Date")} : <Date value={result.startAt} />
          </span>
          <span>
            {t("Number of consent / Number of users")} :{" "}
            {result.agreedUserCount} / {result.totalUserCount}
          </span>
        </caption>
        <thead>
          <tr>
            <th className={styles.userIdColumn}>{t("User ID")}</th>
            <th className={styles.userNameColumn}>{t("User Name")}</th>
            <th className={styles.belongToColumn}>{t("Belong To")}</th>
            <th className={styles.consentDateColumn}>{t("Consent Date")}</th>
          </tr>
        </thead>
        <tbody>
          {result.agreedUsers?.map((user) => (
            <tr key={user.email}>
              <td className={styles.userIdColumn}>
                <EllipsisByLineCount max={1}>{user.email}</EllipsisByLineCount>
              </td>
              <td className={styles.userNameColumn}>
                <EllipsisByLineCount max={1}>{user.name}</EllipsisByLineCount>
              </td>
              <td className={styles.belongToColumn}>
                <EllipsisByLineCount max={1}>
                  {user.organization}
                </EllipsisByLineCount>
              </td>
              <td className={styles.consentDateColumn}>
                <Date value={user.createdAt} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableTheme>
  );
};
export default SearchResultTable;
