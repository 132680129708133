import type { FC } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate } from "react-router-dom";
import { getAuthedWebApi } from "../../webapi";
import { useFlashMessageDispatch } from "../FlashMessage/context";
import type { FormData } from "../TermsForm";
import TermsForm from "../TermsForm";
import styles from "./TermsNew.module.css";

type Props = {};

const TermsNew: FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const submitted = useRef(false);
  useBlocker((tx) => {
    if (
      submitted.current ||
      window.confirm(
        t(
          "Input contents are discarded. You cannot start over. Are you sure you want to discard it?"
        )
      )
    )
      tx.retry();
  });

  const flash = useFlashMessageDispatch();

  return (
    <div className={styles.host}>
      <TermsForm
        validateStartBeforeToday
        onSubmit={(data) => {
          if (window.confirm(t("Are you sure you want to register?")))
            createNewTerms(data).then(() => {
              flash(t("You have successfully registered the terms of use."));
              submitted.current = true;
              navigate("/terms");
            });
        }}
        onCancel={() => navigate("/terms")}
      />
    </div>
  );
};
export default TermsNew;

async function createNewTerms(data: FormData) {
  const webapi = await getAuthedWebApi();

  await webapi.postEndUserLicenseVersions({
    endUserLicenseVersionCreate: data,
  });
}
