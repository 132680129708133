import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthedWebApi, logout } from "../../../../webapi";
import { AppErrorNotLogin } from "../../../../webapi/error";
import { ReactComponent as UpIcon } from "./ic_24_up_wh.svg";
import { ReactComponent as DownIcon } from "./ic_24_pulldown_wh.svg";
import { ReactComponent as SignOutIcon } from "./ic_32_signout.svg";
import styles from "./AccountMenu.module.css";

type Props = {};

const AccountMenu: FC<Props> = () => {
  const username = useAuthenticatedUserName();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        className={styles.host}
        onClick={() => setMenuOpen((s) => !s)}
      >
        <span>{username}</span>
        {menuOpen ? <UpIcon /> : <DownIcon />}
      </button>
      {menuOpen && (
        <div className={styles.menu}>
          <button
            type="button"
            className={styles.menuItem}
            onClick={() => {
              setMenuOpen(false);
              logout();
            }}
          >
            <SignOutIcon />
            <span>Sign Out</span>
          </button>
        </div>
      )}
    </>
  );
};
export default AccountMenu;

function useAuthenticatedUserName() {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const checkLogin = useCallback(async () => {
    try {
      const webapi = await getAuthedWebApi();
      const user = await webapi.getUser();
      setName(user.name);
    } catch (e) {
      if (e instanceof AppErrorNotLogin) {
        navigate("/login");
        return;
      }
      throw e;
    }
  }, [navigate]);

  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  return name;
}
