import type { FC } from "react";
import Header from "./Header";
import styles from "./LayoutEmptyHeader.module.css";

type Props = {};

/**
 * このコンポーネントの責務はページレイアウトとして空のヘッダーを与えること
 */
const LayoutEmptyHeader: FC<Props> = ({ children }) => {
  return (
    <>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.main}>{children}</div>
    </>
  );
};
export default LayoutEmptyHeader;
