import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../../Button";
import InputCheckbox from "../../InputCheckbox";
import InputText from "../../InputText";
import styles from "./SearchForm.module.css";
import { UserRole } from "../../../webapi/generated";
import { useOrganizations } from "../../UserForm";
import SelectByInputText from "../../SelectByInputText";

export type FormData = {
  userId: string;
  userName: string;
  organizationName: string;
  status: Array<"enabled" | "disabled">;
  roles: UserRole[];
};

export enum SearchUserRole {
  User = "user",
  Administrator = "administrator",
  Dealer = "dealer",
}

type Props = {
  defaultValues?: Partial<FormData>;
  onSearch?: (data: FormData) => void;
};

const SearchForm: FC<Props> = ({ defaultValues, onSearch }) => {
  const { t } = useTranslation();
  const { handleSubmit, register } = useForm({ defaultValues });
  const organizations = useOrganizations();

  return (
    <form className={styles.host} onSubmit={handleSubmit(onSearch as any)}>
      <div className={styles.conditions}>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("User ID")}:</span>
          <InputText name="userId" maxLength={254} ref={register} />
        </label>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("User Name")}:</span>
          <InputText name="userName" maxLength={30} ref={register} />
        </label>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("Belong to")}:</span>
          <SelectByInputText
            name="organizationName"
            enforceMatch={false}
            maxLength={1000}
            options={organizations.map((o) => ({ value: o.name }))}
            ref={register}
          />
        </label>
        <div className={styles.formGroup}>
          <span className={styles.labelText}>{t("Status")}:</span>
          <div>
            <label className={styles.status}>
              <InputCheckbox
                name="status"
                value="enabled"
                defaultChecked
                ref={register}
              />
              <span>{t("Enabled")}</span>
            </label>
            <label className={styles.status}>
              <InputCheckbox
                name="status"
                value="disabled"
                defaultChecked
                ref={register}
              />
              <span>{t("Disabled")}</span>
            </label>
          </div>
        </div>
        <div className={styles.formGroup}>
          <span className={styles.labelText}>{t("User's Role")}:</span>
          <div>
            <label className={styles.status}>
              <InputCheckbox
                name="roles"
                value={SearchUserRole.Administrator}
                ref={register}
              />
              <span>{t("Administrator")}</span>
            </label>
            <label className={styles.status}>
              <InputCheckbox
                name="roles"
                value={SearchUserRole.User}
                ref={register}
              />
              <span>{t("User")}</span>
            </label>
            <label className={styles.status}>
              <InputCheckbox
                name="roles"
                value={SearchUserRole.Dealer}
                ref={register}
              />
              <span>{t("Dealer")}</span>
            </label>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button type="submit" className={styles.searchButton}>
          {t("Search")}
        </Button>
      </div>
    </form>
  );
};
export default SearchForm;
