import { useSearchParams } from "react-router-dom";

export function usePage() {
  const [searchParams] = useSearchParams();
  const pageParam = searchParams.get("page") ?? "1";
  try {
    const page = parseInt(pageParam, 10);
    return Number.isNaN(page) ? 1 : page;
  } catch {
    return 1;
  }
}
