import type { To } from "history";
import type { FC } from "react";
import Item from "./Item";
import { generateLinkIndexes } from "./links";
import styles from "./Pager.module.css";

type Props = {
  /**
   * 最大ページ番号 (1-based)
   */
  max: number;
  /**
   * 現在ページ番号 (1-based)
   */
  current: number;
  /**
   * ページ番号 (1-based) から各ページの URL を生成する関数
   */
  navigateTo: (page: number) => To;
};

const Pager: FC<Props> = ({ max, current, navigateTo }) => {
  const links = generateLinkIndexes(current, max);
  if (links.length === 0) return null;
  return (
    <nav className={styles.host}>
      {links.map((type, index) => {
        switch (type) {
          case "…":
          case " ":
            return <Item key={`${type}-${index}`} type={type} />;
          default: {
            const to =
              type === "<<"
                ? navigateTo(1)
                : type === "<"
                ? navigateTo(current - 1)
                : type === ">"
                ? navigateTo(current + 1)
                : type === ">>"
                ? navigateTo(max)
                : navigateTo(type);
            return (
              <Item key={type} type={type} to={to} active={type === current} />
            );
          }
        }
      })}
    </nav>
  );
};
export default Pager;
