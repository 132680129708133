import type { FC } from "react";
import styles from "./LayoutSingleBox.module.css";

type Props = {};

/**
 * このコンポーネントの責務は画面内で一つだけのトピックを扱うときに使用するレイアウトを提供すること
 */
const LayoutSingleBox: FC<Props> = ({ children }) => {
  return (
    <div className={styles.host}>
      <div className={styles.leftBox}></div>
      <div className={styles.rightBox}>
        <div className={styles.innerbox}>{children}</div>
      </div>
    </div>
  );
};
export default LayoutSingleBox;
