import type { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "../../pages/_404";
import ErrorGate from "../ErrorGate";
import relativePathToRouteKey from "./relativePathToRouteKey";
import ScrollToTop from "./ScrollToTop";

/**
 * このコンポーネントの責務は各画面を現在の URL に合わせて表示すること
 */
const PageRouter: FC = () => {
  // src/pages 以下のファイルツリーから、全ページコンポーネント定義をリストップ
  const context = require.context(
    "../../pages",
    true,
    /^\.(\/[a-z0-9-]+|\/_[a-zA-Z0-9]+)+\.[jt]sx?$/
  );

  // 定義済みの全ページコンポーネントをルーティング情報に変換する
  const routeProps = context.keys().map((contextKey) => {
    const routeKey = relativePathToRouteKey(contextKey);
    const component = context(contextKey).default;
    return { routeKey, component };
  });

  // URL がどの画面にも当てはまらない場合は 404 ページを表示する
  routeProps.push({ routeKey: "*", component: PageNotFound });

  // 全ルーティング情報から react-router-dom の <Route> 要素を生成する
  const routes = routeProps.map(({ routeKey, component: C }) => {
    return <Route key={routeKey} path={routeKey} element={<C />} />;
  });

  return (
    <BrowserRouter>
      <ErrorGate>
        <ScrollToTop />
        <Routes>{routes}</Routes>
      </ErrorGate>
    </BrowserRouter>
  );
};
export default PageRouter;
