export type LinkType =
  | "<<" // ページ送り (先頭へ)
  | "<" // ページ送り (前へ)
  | number // ページ番号
  | ">" // ページ送り (次へ)
  | ">>" // ページ送り (末尾へ)
  | "…" // 中略記号
  | " "; // 非表示 (レイアウト上のサイズは存在する)

/**
 * ページャーの各種リンクである、ページ送りやページ番号の要素を生成する
 */
export function generateLinkIndexes(current: number, max: number): LinkType[] {
  if (max <= 1) return [];

  const result: LinkType[] = [];

  const showStartEllipsis = current >= 4;
  const showEndEllipsis = current <= max - 10;
  const arround = arroundNumbers(
    current,
    [2, max - 1],
    10 + (showStartEllipsis ? 0 : 1) + (showEndEllipsis ? 0 : 1)
  );

  result.push(current > 1 ? "<<" : " ");
  result.push(current > 1 ? "<" : " ");
  result.push(1);
  if (showStartEllipsis) result.push("…");
  arround.forEach((x) => result.push(x));
  if (showEndEllipsis) result.push("…");
  result.push(max);
  result.push(current < max ? ">" : " ");
  result.push(current < max ? ">>" : " ");

  return result;
}

function arroundNumbers(x: number, range: [number, number], size: number) {
  const [min, max] = range;
  const start = Math.max(min, Math.min(x - 1, max - (size - 1)));
  const end = Math.min(start + size - 1, max);

  const numbers = [];
  for (let i = start; i <= end; ++i) {
    numbers.push(i);
  }
  return numbers;
}
