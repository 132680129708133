import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate } from "react-router-dom";
import { getAuthedWebApi } from "../../webapi";
import { EndUserLicenseVersionInfo } from "../../webapi/generated";
import Date from "../Date";
import { useFlashMessageDispatch } from "../FlashMessage/context";
import type { FormData } from "../TermsForm";
import TermsForm from "../TermsForm";
import styles from "./TermsEdit.module.css";

type Props = {
  termsId: number;
};

const TermsEdit: FC<Props> = ({ termsId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const submitted = useRef(false);
  useBlocker((tx) => {
    if (
      submitted.current ||
      window.confirm(
        t(
          "Input contents are discarded. You cannot start over. Are you sure you want to discard it?"
        )
      )
    )
      tx.retry();
  });

  const flash = useFlashMessageDispatch();

  const terms = useTerms(termsId);

  return (
    <div className={styles.host}>
      <div className={styles.ahead}>
        <div className={styles.timestamps}>
          <div>
            {t("Registration Date")} : <Date value={terms?.createdAt} />
          </div>
          <div>
            {t("Last Update")} : <Date value={terms?.updatedAt} />
          </div>
        </div>
      </div>
      <TermsForm
        defaultValue={terms}
        onSubmit={(data) => {
          if (window.confirm(t("Are you sure you want to register?")))
            updateTerms(termsId, data).then(() => {
              flash(t("You have successfully edited the Terms of Use."));
              submitted.current = true;
              navigate("/terms");
            });
        }}
        onCancel={() => navigate("/terms")}
      />
    </div>
  );
};
export default TermsEdit;

function useTerms(termsId: Props["termsId"]) {
  const [terms, setTerms] = useState<EndUserLicenseVersionInfo>();

  useEffect(() => {
    (async () => {
      const webapi = await getAuthedWebApi();
      const result = await webapi.getEndUserLicenseVersionsEndUserLicenseVersionId(
        { endUserLicenseVersionId: termsId }
      );
      setTerms(result);
    })();
  }, [termsId]);

  return terms;
}

async function updateTerms(termsId: Props["termsId"], data: FormData) {
  const webapi = await getAuthedWebApi();

  await webapi.putEndUserLicenseVersionsEndUserLicenseVersionId({
    endUserLicenseVersionId: termsId,
    endUserLicenseVersionUpdate: data,
  });
}
