import type { FC } from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { DisplayPage, Information } from "../../webapi/generated";
import Button from "../Button";
import { toJSTFormat } from "../datetime";
import InputCheckbox from "../InputCheckbox";
import InputRadio from "../InputRadio";
import InputText from "../InputText";
import Textarea from "../Textarea";
import styles from "./InformationForm.module.css";

export type FormData = {
  title: string;
  detail: string;
  url: string;
  publishStart: Date;
  publishEnd: Date;
  isEnabled: boolean;
  displayOn: Array<{ displayPage: DisplayPage; isDisplaying: boolean }>;
};

type Props = {
  defaultValue?: Information;
  deletable?: boolean;
  onSubmit?: (data: FormData) => void;
  onDelete?: () => void;
  onCancel?: () => void;
};

const InformationForm: FC<Props> = ({
  defaultValue,
  deletable,
  onSubmit,
  onDelete,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, register, watch, formState, trigger } = useForm({
    mode: "onChange",
  });

  // バリデーションは defaultValue に依存していて、
  // 変化するとバリデーション結果も変わるため強制的に再バリデーションを実行する
  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);
  const values = watch();

  const [error, setError] = useState<"start-before-end">();

  return (
    <form
      className={styles.host}
      onSubmit={handleSubmit((data) => {
        setError(undefined);

        const publishStart = new Date(`${data.publishStart}T00:00:00+09:00`);
        const publishEnd = new Date(`${data.publishEnd}T00:00:00+09:00`);
        if (publishStart.getTime() >= publishEnd.getTime()) {
          setError("start-before-end");
          return;
        }

        onSubmit?.({
          title: data.title,
          detail: data.detail,
          url: data.url,
          publishStart,
          publishEnd,
          isEnabled: data.status === "enabled",
          displayOn: [
            { displayPage: { id: 1 }, isDisplaying: data.displayOnLogin },
            { displayPage: { id: 2 }, isDisplaying: data.displayOnHeader },
          ],
        });
      })}
    >
      <div className={styles.writable}>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("Title")}</span>
          <InputText
            name="title"
            defaultValue={defaultValue?.title}
            required
            maxLength={100}
            ref={register({ required: true })}
          />
        </label>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("Detail")}</span>
          <Textarea
            name="detail"
            defaultValue={defaultValue?.detail}
            counter
            required
            maxLength={500}
            ref={register({ required: true })}
          />
        </label>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("URL（Optional）")}</span>
          <InputText
            name="url"
            defaultValue={defaultValue?.url}
            maxLength={255}
            ref={register}
          />
        </label>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("Publication Period")}</span>
          <div>
            <InputText
              type="date"
              name="publishStart"
              defaultValue={
                defaultValue
                  ? toJSTFormat(defaultValue.publishStart)
                  : undefined
              }
              required
              invalid={error === "start-before-end"}
              ref={register({ required: true })}
            />
            <span className={styles.dateWave}>〜</span>
            <InputText
              type="date"
              name="publishEnd"
              defaultValue={
                defaultValue ? toJSTFormat(defaultValue.publishEnd) : undefined
              }
              required
              invalid={error === "start-before-end"}
              ref={register({ required: true })}
            />
            {error === "start-before-end" && (
              <div className={styles.invalid}>
                {t("For the end date, enter a date newer than the start date.")}
              </div>
            )}
          </div>
        </label>
        <div className={styles.formGroup}>
          <span className={styles.labelText}>{t("Status")}</span>
          <div>
            <label className={styles.choice}>
              <InputRadio
                name="status"
                value="enabled"
                defaultChecked={defaultValue ? defaultValue.isEnabled : true}
                ref={register}
              />
              {t("Enabled")}
            </label>
            <label className={styles.choice}>
              <InputRadio
                name="status"
                value="disabled"
                defaultChecked={
                  defaultValue ? !defaultValue.isEnabled : undefined
                }
                ref={register}
              />
              {t("Disabled")}
            </label>
          </div>
        </div>
        <div className={styles.formGroup}>
          <span className={styles.labelText}>{t("Display")}</span>
          <div>
            <label className={styles.choice}>
              <InputCheckbox
                name="displayOnLogin"
                defaultChecked={
                  defaultValue?.displayOn.find(
                    (x) => x.displayPage.name === "login"
                  )?.isDisplaying
                }
                ref={register}
              />
              {t("login")}
            </label>
            <label className={styles.choice}>
              <InputCheckbox
                name="displayOnHeader"
                defaultChecked={
                  defaultValue?.displayOn.find(
                    (x) => x.displayPage.name === "header"
                  )?.isDisplaying
                }
                ref={register}
              />
              {t("header")}
            </label>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        {deletable && (
          <Button className={styles.deleteButton} onClick={onDelete}>
            {t("Delete")}
          </Button>
        )}
        <div className={styles.spacer} />
        <Button className={styles.cancelButton} onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button
          type="submit"
          className={styles.submitButton}
          disabled={
            !formState.isValid ||
            !(values["displayOnLogin"] || values["displayOnHeader"])
          }
        >
          {t("Register")}
        </Button>
      </div>
    </form>
  );
};
export default InformationForm;
