import classNames from "classnames";
import {
  forwardRef,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./Textarea.module.css";

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  invalid?: boolean;
  counter?: boolean;
};

const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, onChange, invalid, counter, ...rest }, ref) => {
    const [length, setLength] = useState(0);
    const textareaRef = useRef<HTMLTextAreaElement | null>();

    const updateLength = () => {
      const element = textareaRef.current;
      if (element) setLength(element.value.length);
    };

    // defaultValue の変更時、length に反映する
    useEffect(() => updateLength(), [rest.defaultValue]);

    return (
      <div className={styles.host}>
        <textarea
          className={classNames(
            styles.textarea,
            className,
            invalid && styles.invalid
          )}
          onChange={(event) => {
            updateLength();
            onChange?.(event);
          }}
          {...rest}
          ref={(element) => {
            textareaRef.current = element;
            if (ref) {
              if (ref instanceof Function) {
                ref(element);
              } else {
                ref.current = element;
              }
            }
          }}
        />
        {counter && (
          <div className={classNames(styles.counter, !length && styles.zero)}>
            {length}/{rest.maxLength ?? "∞"}
          </div>
        )}
      </div>
    );
  }
);
export default Textarea;
