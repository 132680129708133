import i18n from "i18next";
import I18NextXhrBackend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(I18NextXhrBackend)
  .use(initReactI18next)
  .init({
    // debug: true,
    lng: "ja",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    keySeparator: false,
  });

export default i18n;
