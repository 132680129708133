import classNames from "classnames";
import type { To } from "history";
import type { FC } from "react";
import { Link } from "react-router-dom";
import type { LinkType } from "../links";
import { ReactComponent as LeftIcon } from "./ic_24_arrow_left.svg";
import { ReactComponent as RightIcon } from "./ic_24_arrow_right.svg";
import { ReactComponent as DoubleLeftIcon } from "./ic_24_doublearrow_left.svg";
import { ReactComponent as DoubleRightIcon } from "./ic_24_doublearrow_right.svg";
import styles from "./Item.module.css";

const iconMap = {
  "<<": DoubleLeftIcon,
  "<": LeftIcon,
  ">": RightIcon,
  ">>": DoubleRightIcon,
};

type Props = { active?: boolean } & (
  | {
      type: Exclude<LinkType, "…" | " ">;
      to: To;
    }
  | {
      type: "…" | " ";
    }
);

const Item: FC<Props> = (props) => {
  switch (props.type) {
    case "<<":
    case "<":
    case ">":
    case ">>":
      const Icon = iconMap[props.type];
      return (
        <Link className={styles.link} to={props.to}>
          <Icon />
        </Link>
      );
    case "…":
      return <span className={styles.ellipsis}>…</span>;
    case " ":
      return <span hidden className={styles.space} />;
    default:
      if (typeof props.type === "number")
        return (
          <Link
            className={classNames(styles.link, props.active && styles.active)}
            to={props.to}
          >
            {props.type}
          </Link>
        );
  }
  throw new Error(`unknown link type: ${props.type}`);
};
export default Item;
