import classNames from "classnames/bind";
import type { FC } from "react";
import ReactDOM from "react-dom";
import styles from "./FlashMessage.module.css";

const cx = classNames.bind(styles);

const rootElement = document.querySelector("#flash-message")!;

type Props = {
  message?: string;
  theme?: "blue" | "orange";
};

const FlashMessage: FC<Props> = ({ message, theme = "blue" }) => {
  return ReactDOM.createPortal(
    <>{message && <div className={cx("host", theme)}>{message}</div>}</>,
    rootElement
  );
};
export default FlashMessage;
