import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as InvalidIcon } from "./ic_16_batch_invalid.svg";
import { ReactComponent as ValidIcon } from "./ic_16_batch_valid.svg";
import styles from "./Status.module.css";

type Props = {
  enabled?: boolean;
};

const Status: FC<Props> = ({ enabled }) => {
  const { t } = useTranslation();
  return (
    <span className={styles.host}>
      <span>{enabled ? <ValidIcon /> : <InvalidIcon />}</span>
      <span>{enabled ? t("Enabled") : t("Disabled")}</span>
    </span>
  );
};
export default Status;
