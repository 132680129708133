import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { usePage } from "../hooks";
import Pager from "../Pager";
import { ReactComponent as PlusIcon } from "./ic_32_add.svg";
import styles from "./OrganizationList.module.css";
import OrganizationTable from "./OrganizationTable";
import { useOrganizations } from "./hooks";

type Props = {};

const OrganizationList: FC<Props> = () => {
  const { t } = useTranslation();
  const page = usePage();

  const result = useOrganizations(page);
  return (
    <div>
      <div className={styles.tableView}>
        <Link to="/organizations/new" className={styles.newOrganizationButton}>
          <PlusIcon />
          <span>{t("Add new organization")}</span>
        </Link>
        <OrganizationTable
          organizations={result.organizations}
          count={result.count}
        />
        {result?.count === 0 && (
          <div className={styles.noSearchResult}>{t("No results found")}</div>
        )}
      </div>
      <div className={styles.pager}>
        <Pager
          max={result.pager.max}
          current={result.pager.current}
          navigateTo={(i) => `/organizations?page=${i}`}
        />
      </div>
    </div>
  );
};
export default OrganizationList;
