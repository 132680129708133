import type { FC } from "react";
import { federatedSignIn } from "../../webapi";
import Button from "../Button";
import { ReactComponent as Logo } from "./img_logo.svg";
import styles from "./Login.module.css";

type Props = {};

/**
 * このコンポーネントの責務は Login 画面を提供すること
 */
const Login: FC<Props> = () => {
  return (
    <>
      <div className={styles.form}>
        <Logo />
        <Button
          type="button"
          className={styles.submit}
          onClick={federatedSignIn}
        >
          Sign in with Okta
        </Button>
      </div>
    </>
  );
};
export default Login;
