import type { FC } from "react";
import styles from "./LayoutSidebar.module.css";
import Sidebar from "./Sidebar";

type Props = {};

const LayoutSidebar: FC<Props> = ({ children }) => {
  return (
    <>
      <div className={styles.sidebar}>
        <Sidebar />
      </div>
      <div className={styles.main}>{children}</div>
    </>
  );
};
export default LayoutSidebar;
