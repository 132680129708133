import type { ErrorInfo } from "react";
import { Component } from "react";
import { convertToString, ErrorValue } from "../../webapi/error";
import SystemError from "./SystemError";

type Props = {};
type State = {
  error?: ErrorValue;
};

/**
 * このコンポーネントの責務は全てのエラーを補足し、エラー画面を表示すること
 *
 * このプロジェクトでは React クラスコンポーネントでなく関数コンポーネントのみを使用するが、
 * componentDidCatch は React Hooks で再現できないため、ここのみ使用する
 */
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    (async () => {
      this.setState({
        error: {
          title: "Rendering Error",
          detail:
            (await convertToString(error)) + "\n" + errorInfo.componentStack,
        },
      });
    })();
  }

  render() {
    const error = this.state.error;
    return error ? <SystemError errors={[error]} /> : this.props.children;
  }
}

export default ErrorBoundary;
