import type { FC } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getAuthedWebApi } from "../../webapi";
import type { EndUserLicenseVersionAgreementInfo } from "../../webapi/generated";
import { usePage } from "../hooks";
import Pager from "../Pager";
import SearchResultTable from "./SearchResultTable";
import styles from "./TermsList.module.css";
import { ReactComponent as PlusIcon } from "./ic_32_add.svg";

type Props = {};

const TermsList: FC<Props> = () => {
  const { t } = useTranslation();

  const page = usePage();
  const result = useSearchResult(page);

  return (
    <div>
      <div className={styles.tableView}>
        <Link to="/terms/new" className={styles.newTermUpdateButton}>
          <PlusIcon />
          {t("Update Registration")}
        </Link>
        <SearchResultTable result={result} />
      </div>
      <div className={styles.pager}>
        <Pager
          max={Math.ceil((result?.count ?? 0) / 20)}
          current={page}
          navigateTo={(i) => `/terms?page=${i}`}
        />
      </div>
    </div>
  );
};
export default TermsList;

function useSearchResult(page?: number) {
  const [licenseVersions, setLicenseVersions] = useState<EndUserLicenseVersionAgreementInfo[]>([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    (async () => {
      const webapi = await getAuthedWebApi();
      const result = await webapi.getEndUserLicenseVersions({
        page: page,
        limit: 20,
      });
      setLicenseVersions(result.endUserLicenseVersions || []);
      setCount(result.count);
    })();
  }, [page]);

  return { endUserLicenseVersions: licenseVersions, count };
}
