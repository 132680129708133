import type { FC } from "react";
import { useTranslation } from "react-i18next";
import LayoutEmptyHeader from "../../LayoutEmptyHeader";
import styles from "./MaintenanceInfo.module.css";

type Props = {
  message?: string;
};

/**
 * このコンポーネントの責務は Maintenance 画面でメンテナンス情報を表示すること
 */
const MaintenanceInfo: FC<Props> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <LayoutEmptyHeader>
      <div className={styles.host}>
        <div className={styles.title}>
          <span>{t("System Maintenance in Progress")}</span>
        </div>
        <div className={styles.subTitle}>
          <span>{t("Please try again after a while.")}</span>
        </div>
        <div className={styles.message}>{message}</div>
      </div>
    </LayoutEmptyHeader>
  );
};
export default MaintenanceInfo;
