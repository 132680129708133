import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LinkIcon } from "./ic_24_weblink.svg";
import { ReactComponent as SapliIcon } from "./ic_32_SAPLi.svg";
import styles from "./SapliLink.module.css";

const sapliUrl = process.env.REACT_APP_SAPLI_URL;

type Props = {};

const SapliLink: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <a
      className={styles.host}
      href={sapliUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <SapliIcon />
      <span className={styles.text}>{t("SAPLi")}</span>
      <LinkIcon />
    </a>
  );
};
export default SapliLink;
