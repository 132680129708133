import type { FC } from "react";
import { useEffect, useState } from "react";
import * as maintenance from "./maintenance";
import MaintenanceInfo from "./MaintenanceInfo";

type Props = {};

/**
 * このコンポーネントの責務はメンテナンスモードになったときに検知し、その情報を表示すること
 */
const MaintenanceGate: FC<Props> = ({ children }) => {
  const [isMaintenance, message] = useIsMaintenance();

  return isMaintenance ? (
    <MaintenanceInfo message={message} />
  ) : (
    <>{children}</>
  );
};
export default MaintenanceGate;

function useIsMaintenance() {
  const [message, setMessage] = useState("");
  const [isMaintenance, setIsMaintenance] = useState(false);
  useEffect(() => {
    (async () => {
      const info = await maintenance.getMaintenanceInfo();
      const state = info && info.state === "maintenance" ? true : false;
      const message = info && info.state === "maintenance" ? info.message : "";
      setIsMaintenance(state);
      setMessage(message);
    })();
  }, []);

  return [isMaintenance, message] as const;
}
