import { useEffect } from "react";

export default function usePageTitle(title: string) {
  useEffect(() => {
    document.title = `${title} - SAPLi Administrator`;
    return () => {
      document.title = "SAPLi Administrator";
    };
  }, [title]);
}
