import type { FC } from "react";
import styles from "./Header.module.css";
import { ReactComponent as TitleIcon } from "./img_logo_title.svg";

type Props = {};

const Header: FC<Props> = () => {
  return (
    <header className={styles.host}>
      <TitleIcon />
    </header>
  );
};
export default Header;
