import type { FC } from "react";
import { createContext, useContext, useState } from "react";
import FlashMessage from ".";

type FlashMessageTheme = "blue" | "orange";
export type FlashMessageContextValue = {
  dispatch: (message: string, theme?: FlashMessageTheme) => void;
};

const Context = createContext<FlashMessageContextValue>({
  dispatch: () => {},
});

export const FlashMessageContextProvider: FC = ({ children }) => {
  const [message, setMessage] = useState<string>();
  const [theme, setTheme] = useState<FlashMessageTheme>("blue");

  const dispatch: FlashMessageContextValue["dispatch"] = (
    message,
    theme = "blue"
  ) => {
    setMessage(message);
    setTheme(theme);
    setTimeout(() => setMessage(undefined), 1200);
  };

  return (
    <Context.Provider value={{ dispatch }}>
      {children}
      <FlashMessage message={message} theme={theme} />
    </Context.Provider>
  );
};

export function useFlashMessageDispatch() {
  return useContext(Context).dispatch;
}
