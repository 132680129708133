import classNames from "classnames";
import { ButtonHTMLAttributes, forwardRef } from "react";
import styles from "./Button.module.css";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {};

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ type = "button", className, ...rest }, ref) => {
    return (
      <button
        type={type}
        className={classNames(styles.host, className)}
        {...rest}
        ref={ref}
      />
    );
  }
);
export default Button;
