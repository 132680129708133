import type { FC } from "react";
import { useEffect, useState } from "react";
import { getAuthedWebApi } from "../../webapi";
import type { EndUserLicenseVersionAgreementInfo } from "../../webapi/generated";
import { usePage } from "../hooks";
import Pager from "../Pager";
import SearchResultTable from "./SearchResultTable";
import styles from "./TermsUserList.module.css";

type Props = {
  termsId: number;
};

const TermsUserList: FC<Props> = ({ termsId }) => {
  const page = usePage();
  const result = useSearchResult(termsId, page);

  return (
    <div>
      <h3 className={styles.termsTitle}>{result?.title}</h3>
      <div className={styles.tableView}>
        <SearchResultTable result={result} />
      </div>
      <div className={styles.pager}>
        <Pager
          max={Math.ceil((result?.agreedUserCount ?? 0) / 20)}
          current={page}
          navigateTo={(i) => `/terms/${termsId}/users?page=${i}`}
        />
      </div>
    </div>
  );
};
export default TermsUserList;

function useSearchResult(id: number, page?: number) {
  const [result, setResult] = useState<EndUserLicenseVersionAgreementInfo>();

  useEffect(() => {
    (async () => {
      const webapi = await getAuthedWebApi();
      const result = await webapi.getEndUserLicenseVersionsEndUserLicenseVersionIdUsers(
        {
          endUserLicenseVersionId: id,
          page: page,
          limit: 20,
        }
      );
      setResult(result);
    })();
  }, [id, page]);

  return result;
}
