import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Organization } from "../../../webapi/generated";
import EllipsisByLineCount from "../../EllipsisByLineCount";
import TableTheme from "../../TableTheme";
import styles from "./OrganizationTable.module.css";

type Props = {
  organizations?: Organization[];
  count?: number;
};

const OrganizationTable: FC<Props> = ({ organizations = [], count = 0 }) => {
  const { t } = useTranslation();

  if (organizations?.length === 0) return null;
  return (
    <TableTheme>
      <table className={styles.fixedTable}>
        <caption>
          {t("Total Search Results")} : {count}
        </caption>
        <thead>
          <tr>
            <th className={styles.organizationColumn}>
              {t("Organization Name")}
            </th>
            <th className={styles.countryNameColumn}>{t("Country")}</th>
          </tr>
        </thead>
        <tbody>
          {organizations.map((organization) => (
            <tr key={organization.id}>
              <td className={styles.organizationColumn}>
                <EllipsisByLineCount max={2}>
                  <Link to={`/organizations/${organization.id}`}>
                    {organization.name}
                  </Link>
                </EllipsisByLineCount>
              </td>
              <td className={styles.countryNameColumn}>
                {organization.countryName}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableTheme>
  );
};
export default OrganizationTable;
