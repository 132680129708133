import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import MaintenanceGate from "./components/MaintenanceGate";
import ErrorBoundary from "./components/ErrorBoundary";
import { FlashMessageContextProvider } from "./components/FlashMessage/context";
import PageRouter from "./components/PageRouter";
import "./i18n";
import "./index.css";

const root = document.getElementById("root")!;

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <Suspense fallback={<div>Loading...</div>}>
        <MaintenanceGate>
          <FlashMessageContextProvider>
            <PageRouter />
          </FlashMessageContextProvider>
        </MaintenanceGate>
      </Suspense>
    </ErrorBoundary>
  </StrictMode>,
  root
);

// a11y のため、モーダル表示中はスクリーンリーダーが読み上げ対象から外す要素を指定する
// @see http://reactcommunity.org/react-modal/accessibility/
ReactModal.setAppElement(root);
