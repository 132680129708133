import type { FC } from "react";
import { useRef, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import { InvalidParameterErrorErrors } from "../../webapi/generated";
import OrganizationForm from "../OrganizationForm";
import { postOrganizations } from "../../usecase/organization";
import { useFlashMessageDispatch } from "../FlashMessage/context";
import { useTranslation } from "react-i18next";

type Props = {};

const OrganizationNew: FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const submitted = useRef(false);
  useBlocker((tx) => {
    if (
      submitted.current ||
      window.confirm(
        t(
          "Input contents are discarded. You cannot start over. Are you sure you want to discard it?"
        )
      )
    )
      tx.retry();
  });

  const flash = useFlashMessageDispatch();
  const [errors, setErrors] = useState<InvalidParameterErrorErrors[]>();

  return (
    <OrganizationForm
      serverErrors={errors}
      onSubmit={(data) => {
        postOrganizations(data)
          .then(() => {
            submitted.current = true;
            flash(t("The organization profile was added successfully."));
            navigate("/organizations");
          })
          .catch(async (exception: unknown) => {
            if (exception instanceof Response && exception.status === 400) {
              const responseBody = await exception.json();
              setErrors(responseBody.errors);
              return;
            }
            throw exception;
          });
      }}
      onCancel={() => navigate("/organizations")}
    />
  );
};
export default OrganizationNew;
