import type { FC } from "react";
import { useEffect, useState } from "react";
import {
  convertToError,
  ErrorValue,
  isAppErrorNotLoginError,
  isInvalidLoginSessionError,
} from "../../webapi/error";
import SystemError from "../ErrorBoundary/SystemError";
import * as maintenance from "../MaintenanceGate/maintenance";
import { logout } from "../../webapi";

type Props = {};
/**
 * このコンポーネントの責務はエラー発生時、エラーを画面に表示すること
 */
const ErrorGate: FC<Props> = ({ children }) => {
  const errors = useWindowErrorEventHandler();
  if (errors.length > 0) {
    return <SystemError errors={errors} />;
  }

  return <>{children}</>;
};
export default ErrorGate;

function useWindowErrorEventHandler() {
  const [errors, setErrors] = useState<ErrorValue[]>([]);

  useEffect(() => {
    const detected = (x: unknown) => {
      (async () => {
        try {
          if (isAppErrorNotLoginError(x)) return;
          if (await isInvalidLoginSessionError(x)) {
            logout();
            return;
          }
          setErrors([...errors, await convertToError(x)]);

          // エラー発生時はメンテナンス状態をチェックする
          // メンテナンスモードの時、MaintenanceGate コンポーネントにより
          // メンテナンス画面が表示されるようになる
          const info = await maintenance.getMaintenanceInfo();
          if (info?.state === "maintenance") {
            window.location.reload();
          }
        } catch {
          // 無限ループにならないように全てのエラーを無視する
        }
      })();
    };
    const onError = (event: ErrorEvent) => detected(event);
    const onUnhandledRejection = (event: PromiseRejectionEvent) =>
      detected(event.reason);

    window.addEventListener("error", onError);
    window.addEventListener("unhandledrejection", onUnhandledRejection);

    return () => {
      window.removeEventListener("error", onError);
      window.removeEventListener("unhandledrejection", onUnhandledRejection);
    };
  });

  return errors;
}
