import classNames from "classnames";
import type { FC } from "react";
import { Children, cloneElement, isValidElement } from "react";
import styles from "./TableTheme.module.css";

type Props = {};

/**
 * このコンポーネントの責務は子コンポーネントとして与えた table 要素関連の要素の見た目を修飾すること
 */
const TableTheme: FC<Props> = ({ children }) => {
  return (
    <>
      {Children.map(children, (child) => {
        // 不正な要素ならそのままレンダリングする
        if (!isValidElement(child)) return child;

        // table 要素以外はそのままレンダリングする
        if (child.type !== "table") {
          // 開発環境なら子コンポーネントが table 要素であるかチェックして、不正なら警告を出す
          if (process.env.NODE_ENV === "development")
            console.warn(
              "TableTheme コンポーネントは子要素として table 要素のみ受け付けます"
            );
          return child;
        }

        // table 要素はこのコンポーネント独自のクラスを追加する
        const className = classNames(child.props.className, styles.host);

        return cloneElement(child, { className });
      })}
    </>
  );
};
export default TableTheme;
