import classNames from "classnames";
import { forwardRef, InputHTMLAttributes, useState } from "react";
import Button from "../Button";
import { ReactComponent as EyeOffIcon } from "./ic_32_eye_off.svg";
import { ReactComponent as EyeOnIcon } from "./ic_32_eye_on.svg";
import styles from "./InputText.module.css";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  invalid?: boolean;
  unmaskable?: boolean;
};

const InputText = forwardRef<HTMLInputElement, Props>(
  ({ className, type, invalid, unmaskable, ...rest }, ref) => {
    const [unmasking, setUnmasking] = useState(false);

    const content = (
      <input
        className={classNames(
          styles.host,
          className,
          invalid && styles.invalid,
          unmaskable && styles.unmaskable
        )}
        type={unmaskable && unmasking ? "text" : type}
        {...rest}
        ref={ref}
      />
    );

    if (unmaskable) {
      return (
        <div className={styles.unmaskableInput}>
          {content}
          <Button
            onClick={() => setUnmasking((prev) => !prev)}
            className={styles.unmaskButton}
          >
            {unmasking ? <EyeOffIcon /> : <EyeOnIcon />}
          </Button>
        </div>
      );
    }

    return content;
  }
);
export default InputText;
