import type { FC } from "react";
import { useTranslation } from "react-i18next";
import LayoutEmptyHeader from "../LayoutEmptyHeader";
import styles from "./PageNotFound.module.css";
type Props = {};

/**
 * このコンポーネントの責務は PageNotFound 画面を提供する
 */
const PageNotFound: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <LayoutEmptyHeader>
      <div className={styles.host}>
        <div className={styles.title}>{t("404 Not Found")}</div>
        <div className={styles.message}>
          {t("Sorry, but the page you are looking for cannot be found.")}
        </div>
      </div>
    </LayoutEmptyHeader>
  );
};
export default PageNotFound;
