import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as AnnounceBlackIcon } from "./ic_32_announce_announce_bk.svg";
import { ReactComponent as AnnounceWhiteIcon } from "./ic_32_announce_announce_wh.svg";
import { ReactComponent as PrivacyBlackIcon } from "./ic_32_privacy_privacy_bk.svg";
import { ReactComponent as PrivacyWhiteIcon } from "./ic_32_privacy_privacy_wh.svg";
import { ReactComponent as UserBlackIcon } from "./ic_32_user_user_bk.svg";
import { ReactComponent as UserWhiteIcon } from "./ic_32_user_user_wh.svg";
import { ReactComponent as OrganizationBlackIcon } from "./ic_32_organization_organization_bk.svg";
import { ReactComponent as OrganizationWhiteIcon } from "./ic_32_organization_organization_wh.svg";

import Item from "./Item";
import SapliLink from "./SapliLink";
import styles from "./Sidebar.module.css";

type Props = {};

const Sidebar: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <nav className={styles.host}>
      <Item
        to="/users"
        icon={<UserWhiteIcon />}
        activeIcon={<UserBlackIcon />}
        text={t("User")}
      />
      <Item
        to="/organizations"
        icon={<OrganizationWhiteIcon />}
        activeIcon={<OrganizationBlackIcon />}
        text={t("Organization")}
      />
      <Item
        to="/informations"
        icon={<AnnounceWhiteIcon />}
        activeIcon={<AnnounceBlackIcon />}
        text={t("Information")}
      />
      <Item
        to="/terms"
        icon={<PrivacyWhiteIcon />}
        activeIcon={<PrivacyBlackIcon />}
        text={t("Terms of Use")}
      />
      <SapliLink />
    </nav>
  );
};
export default Sidebar;
