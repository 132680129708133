import type { FC } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate } from "react-router-dom";
import { getAuthedWebApi } from "../../webapi";
import { useFlashMessageDispatch } from "../FlashMessage/context";
import InformationForm, { FormData } from "../InformationForm";

type Props = {};

const InformationNew: FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const submitted = useRef(false);
  useBlocker((tx) => {
    if (
      submitted.current ||
      window.confirm(
        t(
          "Input contents are discarded. You cannot start over. Are you sure you want to discard it?"
        )
      )
    )
      tx.retry();
  });

  const flash = useFlashMessageDispatch();

  return (
    <InformationForm
      onSubmit={(data) => {
        if (window.confirm(t("Are you sure you want to register?")))
          createNewInformation(data).then(() => {
            flash(t("The information was successfully registered."));
            submitted.current = true;
            navigate("/informations");
          });
      }}
      onCancel={() => navigate("/informations")}
    />
  );
};
export default InformationNew;

async function createNewInformation(data: FormData) {
  const webapi = await getAuthedWebApi();

  await webapi.postInformations({ informationCreate: data });
}
