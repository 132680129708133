import { useEffect, FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InvalidParameterErrorErrors } from "../../webapi/generated";
import Button from "../Button";
import InputText from "../InputText";
import SelectByInputText from "../SelectByInputText";
import styles from "./OrganizationForm.module.css";
import { useCountries } from "./hooks";

export type FormData = {
  name: string;
  countryCode: string;
  countryName: string;
};

type Props = {
  defaultValue?: FormData;
  deletable?: boolean;
  serverErrors?: InvalidParameterErrorErrors[];
  onSubmit?: (data: FormData) => void;
  onDelete?: () => void;
  onCancel?: () => void;
};

const OrganizationForm: FC<Props> = ({
  defaultValue,
  deletable,
  serverErrors = [],
  onSubmit,
  onDelete,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, register, formState, trigger } = useForm({
    mode: "onChange",
  });

  const countries = useCountries();
  // バリデーションは defaultValue に依存していて、
  // 変化するとバリデーション結果も変わるため強制的に再バリデーションを実行する
  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, countries]);

  const organizationNameError = serverErrors.find((e) => e.name === "name");
  const countryError = serverErrors.find((e) => e.name === "countryCode");

  return (
    <form
      onSubmit={handleSubmit((data: FormData) => {
        if (!onSubmit) return;
        const found = countries.find((c) => c.name === data.countryName);
        if (!found) throw new Error("unexpected country value");
        onSubmit({
          name: data.name,
          countryName: found.name,
          countryCode: found.code,
        });
      })}
    >
      <div className={styles.writable}>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("Organization Name")}</span>
          <InputText
            type="text"
            name="name"
            defaultValue={defaultValue?.name}
            required
            maxLength={1000}
            ref={register({ required: true })}
            invalid={!!organizationNameError}
          />
        </label>
        {organizationNameError && (
          <div className={styles.invalid}>{organizationNameError.message}</div>
        )}
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("Country")}</span>
          <SelectByInputText
            name="countryName"
            options={countries.map((c) => ({ value: c.name }))}
            defaultValue={defaultValue?.countryName}
            required
            ref={register({
              required: true,
              validate: (value) =>
                Boolean(countries.find((c) => c.name === value)),
            })}
            invalid={!!countryError}
          />
        </label>
        {countryError && (
          <div className={styles.invalid}>{countryError.message}</div>
        )}
      </div>
      <div className={styles.footer}>
        {!!defaultValue && (
          <Button
            className={styles.deleteButton}
            onClick={onDelete}
            disabled={!deletable}
          >
            {t("Delete")}
          </Button>
        )}
        <div className={styles.spacer} />
        <Button className={styles.cancelButton} onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button
          type="submit"
          className={styles.submitButton}
          disabled={!formState.isValid}
        >
          {t("Register")}
        </Button>
      </div>
    </form>
  );
};
export default OrganizationForm;
