import type { FC } from "react";
import { ComponentProps, ReactElement } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Item.module.css";

type Props = Pick<ComponentProps<typeof NavLink>, "to"> & {
  icon?: ReactElement;
  activeIcon?: ReactElement;
  text?: ReactElement;
};

const Item: FC<Props> = ({ to, icon, activeIcon, text }) => {
  return (
    <NavLink to={to} className={styles.host} activeClassName={styles.active}>
      <span className={styles.icon}>{icon}</span>
      <span className={styles.activeIcon}>{activeIcon}</span>
      <span className={styles.text}>{text}</span>
    </NavLink>
  );
};
export default Item;
