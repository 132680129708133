import classNames from "classnames";
import { forwardRef, InputHTMLAttributes } from "react";
import styles from "./InputCheckbox.module.css";

type Props = InputHTMLAttributes<HTMLInputElement> & {};

const InputCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ className, ...rest }, ref) => {
    return (
      <input
        type="checkbox"
        className={classNames(styles.host, className)}
        {...rest}
        ref={ref}
      />
    );
  }
);
export default InputCheckbox;
