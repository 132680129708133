import type { FC } from "react";
import { useTranslation } from "react-i18next";
import LayoutHeader from "../../components/LayoutHeader";
import LayoutPageContent from "../../components/LayoutPageContent";
import LayoutSidebar from "../../components/LayoutSidebar";
import PageTitle from "../../components/PageTitle";
import usePageTitle from "../usePageTitle";
import { ReactComponent as Icon } from "./ic_32_organization_organization_bk.svg";
import OrganizationNew from "../../components/OrganizationNew";

const Organization: FC = () => {
  usePageTitle("Organization Registration");
  const { t } = useTranslation();

  return (
    <LayoutHeader>
      <LayoutSidebar>
        <PageTitle icon={<Icon />} title={t("Add New Organization")} />
        <LayoutPageContent>{<OrganizationNew />}</LayoutPageContent>
      </LayoutSidebar>
    </LayoutHeader>
  );
};
export default Organization;
