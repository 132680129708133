import type { FC } from "react";
import { ReactElement } from "react";
import styles from "./PageTitle.module.css";

type Props = {
  icon: ReactElement;
  title: string;
};

const PageTitle: FC<Props> = ({ icon, title }) => {
  return (
    <h2 className={styles.host}>
      <span>{icon}</span>
      <span>{title}</span>
    </h2>
  );
};
export default PageTitle;
