import type { FC } from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getAuthedWebApi } from "../../webapi";
import {
  Organization,
  UserRole,
  User,
  InvalidParameterErrorErrors,
} from "../../webapi/generated";
import Button from "../Button";
import InputRadio from "../InputRadio";
import InputText from "../InputText";
import SelectByInputText from "../SelectByInputText";
import Textarea from "../Textarea";
import styles from "./UserForm.module.css";

export type FormData = {
  userId: string;
  firstName: string;
  lastName: string;
  organizationId: number;
  status: "enabled" | "disabled";
  userType: UserRole;
  remarks: string;
};

type Props = {
  defaultValue?: User;
  deletable?: boolean;
  hiddenStatus?: boolean;
  serverErrors?: InvalidParameterErrorErrors[];
  onSubmit?: (data: FormData) => void;
  onDelete?: () => void;
  onCancel?: () => void;
};

const UserForm: FC<Props> = ({
  defaultValue,
  deletable,
  hiddenStatus,
  serverErrors = [],
  onSubmit,
  onDelete,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, register, formState, trigger } = useForm({
    mode: "onChange",
  });

  const organizations = useOrganizations();

  // バリデーションは defaultValue, organizations に依存していて、
  // 変化するとバリデーション結果も変わるため強制的に再バリデーションを実行する
  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, organizations]);
  const emailError = serverErrors.find((e) => e.name === "email");
  const organizationIdError = serverErrors.find(
    (e) => e.name === "organizationId"
  );

  return (
    <form
      onSubmit={handleSubmit((data: any) => {
        if (!onSubmit) return;
        const { organization, ...rest } = data;
        const found = organizations.find((o) => o.name === organization);
        if (!found) throw new Error("unexpected organization value");
        onSubmit({ ...rest, organizationId: found.id });
      })}
    >
      <div className={styles.writable}>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("E-mail (User ID)")}</span>
          <InputText
            type="email"
            name="userId"
            defaultValue={defaultValue?.email}
            required
            maxLength={254}
            ref={register({ required: true })}
            invalid={!!emailError}
          />
        </label>
        {emailError && (
          <div className={styles.invalid}>{emailError.message}</div>
        )}
        <div className={styles.formGroup}>
          <span className={styles.labelText}>{t("User Name")}</span>
          <div className={styles.innerGroup}>
            <label className={styles.formGroup}>
              <span className={styles.labelText}>{t("First Name")}</span>
              <InputText
                name="firstName"
                defaultValue={defaultValue?.firstName}
                required
                maxLength={50}
                ref={register({ required: true })}
              />
            </label>
            <label className={styles.formGroup}>
              <span className={styles.labelText}>{t("Last Name")}</span>
              <InputText
                name="lastName"
                defaultValue={defaultValue?.lastName}
                required
                maxLength={50}
                ref={register({ required: true })}
              />
            </label>
          </div>
        </div>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("Belong to")}</span>
          <SelectByInputText
            name="organization"
            options={organizations.map((o) => ({ value: o.name }))}
            defaultValue={defaultValue?.organizationName}
            required
            ref={register({
              required: true,
              validate: (value) =>
                Boolean(organizations.find((o) => o.name === value)),
            })}
            invalid={!!organizationIdError}
          />
        </label>
        {organizationIdError && (
          <div className={styles.invalid}>{organizationIdError.message}</div>
        )}
        <div className={styles.formGroup} hidden={hiddenStatus}>
          <span className={styles.labelText}>{t("Status")}</span>
          <div>
            <label className={styles.choice}>
              <InputRadio
                name="status"
                value="enabled"
                defaultChecked={defaultValue ? defaultValue.isEnabled : true}
                ref={register}
              />
              {t("Enabled")}
            </label>
            <label className={styles.choice}>
              <InputRadio
                name="status"
                value="disabled"
                defaultChecked={
                  defaultValue ? !defaultValue.isEnabled : undefined
                }
                ref={register}
              />
              {t("Disabled")}
            </label>
          </div>
        </div>
        <div className={styles.formGroup}>
          <span className={styles.labelText}>{t("User's role")}</span>
          <div>
            <label className={styles.choice}>
              <label className={styles.choice}>
                <InputRadio
                  name="userType"
                  value={UserRole.SystemAdministrator}
                  defaultChecked={
                    defaultValue
                      ? defaultValue.role === UserRole.SystemAdministrator
                      : undefined
                  }
                  ref={register}
                />
                {t(UserRole.SystemAdministrator)}
              </label>
              <InputRadio
                name="userType"
                value={UserRole.User}
                defaultChecked={
                  defaultValue ? defaultValue.role === UserRole.User : true
                }
                ref={register}
              />
              {t(UserRole.User)}
            </label>
            <label className={styles.choice}>
              <InputRadio
                name="userType"
                value={UserRole.Dealer}
                defaultChecked={
                  defaultValue
                    ? defaultValue.role === UserRole.Dealer
                    : undefined
                }
                ref={register}
              />
              {t(UserRole.Dealer)}
            </label>
          </div>
        </div>
        <label className={styles.formGroup}>
          <span className={styles.labelText}>{t("Remarks (Optional)")}</span>
          <Textarea
            name="remarks"
            defaultValue={defaultValue?.remarks}
            counter
            maxLength={200}
            ref={register}
          />
        </label>
      </div>
      <div className={styles.footer}>
        {deletable && (
          <Button className={styles.deleteButton} onClick={onDelete}>
            {t("Delete")}
          </Button>
        )}
        <div className={styles.spacer} />
        <Button className={styles.cancelButton} onClick={onCancel}>
          {t("Cancel")}
        </Button>
        <Button
          type="submit"
          className={styles.submitButton}
          disabled={!formState.isValid}
        >
          {t("Register")}
        </Button>
      </div>
    </form>
  );
};
export default UserForm;

export function useOrganizations() {
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  useEffect(() => {
    (async () => {
      const webapi = await getAuthedWebApi();
      const { organizations } = await webapi.getOrganizations({});
      setOrganizations(organizations);
    })();
  }, []);

  return organizations;
}
