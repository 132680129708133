import type { FC } from "react";
import { useTranslation } from "react-i18next";
import LayoutHeader from "../../components/LayoutHeader";
import LayoutPageContent from "../../components/LayoutPageContent";
import LayoutSidebar from "../../components/LayoutSidebar";
import PageTitle from "../../components/PageTitle";
import TermsList from "../../components/TermsList";
import usePageTitle from "../usePageTitle";
import { ReactComponent as Icon } from "./ic_32_privacy_privacy_bk.svg";

const Terms: FC = () => {
  usePageTitle("Terms of Use Update List");
  const { t } = useTranslation();

  return (
    <LayoutHeader>
      <LayoutSidebar>
        <PageTitle
          icon={<Icon />}
          title={t("Terms of Use Update List")}
        ></PageTitle>
        <LayoutPageContent>
          <TermsList />
        </LayoutPageContent>
      </LayoutSidebar>
    </LayoutHeader>
  );
};
export default Terms;
