import { Auth } from "@aws-amplify/auth";
import { AppErrorNotLogin } from "./error";
import webapi from "./instance";

Auth.configure({
  region: "ap-northeast-1",
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN_URL,
    redirectSignOut: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT_URL,
    responseType: "code",
    scope: ["email", "openid"],
  },
  authenticationFlowType: "USER_PASSWORD_AUTH",
});

export function federatedSignIn() {
  Auth.federatedSignIn({ customProvider: "Okta" });
}

export function logout() {
  Auth.signOut();
}

export async function getAuthedWebApi() {
  let jwtToken: string;
  try {
    const session = await Auth.currentSession();
    jwtToken = session.getIdToken().getJwtToken();
  } catch (e) {
    throw new AppErrorNotLogin("Not Logged in");
  }

  return webapi.withPreMiddleware(async ({ url, init }) => {
    const headers = new Headers(init.headers);
    headers.set("authorization", jwtToken);
    headers.set("accept-language", "en");
    return { url, init: { ...init, headers } };
  });
}
