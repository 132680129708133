import { useEffect, useState } from "react";
import { OrganizationDetail } from "../../webapi/generated";
import { fetchOrganizationDetail } from "../../usecase/organization";

type Props = {
  organizationId: number;
};

export function useOrganization(organizationId: Props["organizationId"]) {
  const [organization, setOrganization] = useState<OrganizationDetail>();

  useEffect(() => {
    (async () => {
      const result = await fetchOrganizationDetail(organizationId);
      setOrganization(result);
    })();
  }, [organizationId]);

  return organization;
}
