import type { FC } from "react";
import { useTranslation } from "react-i18next";
import LayoutHeader from "../../components/LayoutHeader";
import LayoutPageContent from "../../components/LayoutPageContent";
import LayoutSidebar from "../../components/LayoutSidebar";
import PageTitle from "../../components/PageTitle";
import UserNew from "../../components/UserNew";
import usePageTitle from "../usePageTitle";
import { ReactComponent as Icon } from "./ic_32_user_user_bk.svg";

const Users: FC = () => {
  usePageTitle("Add New User");
  const { t } = useTranslation();

  return (
    <LayoutHeader>
      <LayoutSidebar>
        <PageTitle icon={<Icon />} title={t("Add New User")} />
        <LayoutPageContent>
          <UserNew />
        </LayoutPageContent>
      </LayoutSidebar>
    </LayoutHeader>
  );
};
export default Users;
