import type { FC } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {};

/**
 * このコンポーネントの責務は画面遷移するたびにスクロール位置を最上部にリセットする機能を提供すること
 * @see https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
const ScrollToTop: FC<Props> = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
export default ScrollToTop;
